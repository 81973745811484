import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Popover, Typography } from "@material-ui/core";
import { ArrowLeft, Menu as MenuIcon, PowerSettingsNew } from "@material-ui/icons";
import { isEmpty } from "lodash";
import { useRouteMatch, useHistory } from "react-router-dom";

import { AppState } from "libs/redux";
import { getNameInitials } from "libs/utils/helpers";
import { signOut } from "modules/auth/store/actions/actionCreators";

import logo from "libs/assets/leadoo-logo-greenwhite.png";
import smallLogo from "libs/assets/Icon-white-small.png";

import useStyles from "./styles";

interface Props {
  isDrawerOpened: boolean;
  toggleDrawer: () => void;
}

interface State {
  isPopoverOpened: boolean;
  anchorEl: any;
}

const Header: React.FC<Props> = ({ toggleDrawer, isDrawerOpened }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({
    isPopoverOpened: false,
    anchorEl: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.auth.user);
  const title = useSelector((state: AppState) => state.companyManagement.company?.name);
  const isCompanyViewRoute = useRouteMatch("/company/view/");
  const isOrganizationProfileRoute = useRouteMatch("/company/:companyId/organization-profile");
  const isAISettingsRoute = useRouteMatch("/company/:companyId/ai-settings");

  const handleBack = (): void => {
    history.goBack();
  };

  const handleLogout = (): void => {
    dispatch(signOut());
  };

  const getAnchorElement = (anchorEl: any): void => {
    setState({
      ...state,
      anchorEl,
    });
  };

  const togglePopover = (event: React.MouseEvent<HTMLButtonElement> | React.SyntheticEvent): void => {
    getAnchorElement(event.currentTarget);

    setState({
      ...state,
      isPopoverOpened: !state.isPopoverOpened,
    });
  };

  const closePopover = (): void => {
    setState({
      ...state,
      anchorEl: null,
    });
  };

  return (
    <div className={classes.container}>
      <div className={isDrawerOpened ? classes.logoBlock : classes.collapsedLogoBlock}>
        <img
          className={isDrawerOpened ? classes.logo : classes.collapsedLogo}
          src={isDrawerOpened ? logo : smallLogo}
          alt="logo"
        />
      </div>

      <Grid container justifyContent="space-between" alignItems="center">
        <div className={classes.controlContainer}>
          <Button className={classes.expandButton} variant="text" color="inherit" onClick={toggleDrawer}>
            {isDrawerOpened ? <ArrowLeft /> : <MenuIcon />}
          </Button>
          {isCompanyViewRoute && (
            <Typography>
              {title}
            </Typography>
          )}
          {(isOrganizationProfileRoute || isAISettingsRoute) && (
            <Button className={classes.backButton} variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}
        </div>

        {!isEmpty(user) && (
          <>
            <Button className={classes.userDropdown} variant="text" onClick={togglePopover}>
              <span className={classes.userAvatar}>
                {getNameInitials(String(user!.name))}
              </span>
            </Button>

            <Popover
              className={classes.logout}
              open={state.isPopoverOpened}
              onClick={togglePopover}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={state.anchorEl}
            >
              <Button variant="text" onClick={handleLogout}>
                <Typography className={classes.logoutButtonText}>
                  <PowerSettingsNew />
                  Logout
                </Typography>
              </Button>
            </Popover>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Header;
