import React from "react";
import {Box, Checkbox, FormControlLabel, IconButton, Tooltip,} from "@material-ui/core";
import {ReactComponent as TooltipIcon} from "libs/assets/tooltip.svg";
import {ReactComponent as SettingsIcon} from "libs/assets/settings-button.svg";
import {ReactComponent as DangerIcon} from "libs/assets/danger-icon.svg";

import {LimitField, Option, RenderLimitsFieldsProps} from "./types";
import {CheckboxOption, LinkOptions, RenderCheckboxProps,} from "libs/utils/types";
import {FieldTypes, LimitsKeys, Module, ModuleKeys} from "libs/models";

export const filterModulesObject = (
  module: Module,
  filterer: Array<ModuleKeys>,
): Module => {
  const filteredModule = Object.keys(module)
    .filter((moduleKey) => !filterer.includes(moduleKey as ModuleKeys))
    .reduce(
      (prev, moduleKey) => ({
        ...prev,
        [moduleKey]: module[moduleKey as ModuleKeys] || 0,
      }),
      {},
    );

  return filteredModule as Module;
};

export function getNameInitials(name: string): string {
  const names = name.split(" ");
  return `${names[0][0]}${names[names.length - 1][0]}`;
}

export function getQueryParams<T>({
  url,
  queryParams,
  isFree = false,
}: {
  url: string;
  queryParams: Array<string>;
  isFree?: boolean;
}): T | any {
  let params: T | any = {};

  queryParams.forEach((param: string) => {
    const queryParam = new URLSearchParams(url).get(param);

    params = {
      ...params,
      [param]: queryParam === null ? "" : new URLSearchParams(url).get(param),
      is_free: isFree ? 1 : 0,
    };
  });

  return params;
}

export const getRenderCheckbox =
  ({
    module,
    handler,
    companyIdentificationHandler,
    artificialIntelligenceHandler,
    disabled,
    className,
    labelClassName,
    tooltipClassName,
    tooltipPopper,
  }: RenderCheckboxProps) =>
    (checkbox: CheckboxOption, index: number): JSX.Element => {
      return (
        <Box display={"flex"} alignItems={"center"}>
          <FormControlLabel
            key={index}
            label={checkbox.label}
            className={labelClassName || ""}
            disabled={disabled}
            control={
              <Checkbox
                name={checkbox.name}
                color="primary"
                onChange={handler}
                checked={
                  checkbox.name === ModuleKeys.is_company_identification
                    ? module
                      ? !!module[ModuleKeys.is_company_identification]
                      : false
                    : module
                      ? !!module[checkbox.name]
                      : false
                }
                className={className || ""}
              />
            }
          />
          {checkbox.bubbleMessage && (
            <Tooltip
              className={tooltipClassName || ""}
              title={checkbox.bubbleMessage}
              placement="right-start"
              classes={{
                tooltip: tooltipPopper || "",
              }}
            >
              <TooltipIcon/>
            </Tooltip>
          )}
          {checkbox.name === ModuleKeys.is_company_identification && (
            <IconButton
              style={{background: "#CEDAEF", marginLeft: "4px"}}
              size="small"
              onClick={companyIdentificationHandler}
            >
              <SettingsIcon/>
            </IconButton>
          )}
          {checkbox.name === ModuleKeys.is_artificial_intelligence && (
            <IconButton
              style={{background: "#CEDAEF", marginLeft: "4px"}}
              size="small"
              onClick={artificialIntelligenceHandler}
            >
              <SettingsIcon/>
            </IconButton>
          )}
          {checkbox.name === ModuleKeys.is_company_identification &&
            !module?.is_visitor_tracking && (
            <Tooltip
              className={tooltipClassName || ""}
              title={"Visitor tracking is disabled"}
              placement="right-start"
              classes={{
                tooltip: tooltipPopper || "",
              }}
            >
              <DangerIcon/>
            </Tooltip>
          )}
          {checkbox.name === ModuleKeys.is_company_identification &&
            !module?.is_analytics_tracking_core && (
            <Tooltip
              className={tooltipClassName || ""}
              title={"Analytics & Tracking core is disabled"}
              placement="right-start"
              classes={{
                tooltip: tooltipPopper || "",
              }}
            >
              <DangerIcon/>
            </Tooltip>
          )}
        </Box>
      );
    };

export const renderLimits =
  ({
    limits,
    handler,
    current_limits,
    domainAllowlistingHandler,
    disabled,
    fieldClassName,
    labelClassName,
    labelTextClassName,
    tooltipClassName,
    className,
    tooltipPopper,
    fieldInputClassName,
    arrayItemsClassName,
    inputHandler
  }: RenderLimitsFieldsProps) =>
    (limit: LimitField, index: number) => {
      const currentLimit = current_limits ? current_limits[limit.name] : 0;
      const finalLimit = limits ? limits[limit.name] : 0;
      return (
        <Box
          className={className || ""}
          display={"flex"}
          key={index}
          alignItems={"center"}
        >
          <div className={labelClassName}>
            <Box display={"flex"} alignItems={"center"}>
              <span
                className={labelTextClassName}
                style={{fontSize: "14px", fontWeight: "500"}}
              >
                {limit.label}
              </span>
              {limit.bubbleMessage && (
                <Tooltip
                  className={tooltipClassName || ""}
                  title={limit.bubbleMessage}
                  placement="right-start"
                  classes={{
                    tooltip: tooltipPopper || "",
                  }}
                >
                  <TooltipIcon/>
                </Tooltip>
              )}
              {limit.name === LimitsKeys.DomainAllowListing && (
                <IconButton
                  style={{background: "#CEDAEF", marginLeft: "4px"}}
                  size="small"
                  onClick={domainAllowlistingHandler}
                >
                  <SettingsIcon/>
                </IconButton>
              )}
            </Box>
            {limit.description && (
              <div>
                {limit.description} {currentLimit} / {finalLimit}
              </div>
            )}
          </div>

          <div className={fieldClassName}>
            {limit.fieldType !== FieldTypes.ARRAY && (
              <input
                className={fieldInputClassName}
                type={limit.fieldType}
                name={limit.name}
                onKeyDown={inputHandler}
                defaultValue={limits ? limits[limit.name] || "" : ""}
                onChange={handler}
                disabled={disabled}
              />
            )}
            {limit.fieldType === FieldTypes.ARRAY ? (
              limits?.domain_allow_listing?.length ? (
                <span className={arrayItemsClassName}>
                (
                  {limits?.domain_allow_listing?.map(
                    (domain: string, index: number) => (
                      <span key={`domain_${index}`}>
                        {domain}
                        {limits &&
                          limits?.domain_allow_listing &&
                          index !== limits?.domain_allow_listing?.length - 1 &&
                          ", "}
                      </span>
                    ),
                  )}
                  )
                </span>
              ) : (
                <>(All)</>
              )
            ) : (
              <></>
            )}
          </div>
        </Box>
      );
    };

export const getOptions = ({
  options,
  title = "name",
  value = "id",
}: {
  options: Array<any>;
  title?: string;
  value?: string;
}): Array<Option> =>
  options.map?.((option) => ({
    title: option[title],
    value: option[value],
  })) ?? [];

export const getLinks = (
  links: Array<LinkOptions>,
  isSuper: boolean,
): Array<LinkOptions> => {
  if (isSuper) {
    return links;
  }

  return links.filter((link) => !link.isSuper);
};
