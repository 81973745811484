import { isArray, isEmpty, merge } from "lodash";

import { Company, CompanyChurnData } from "libs/models";
import { Contract } from "modules/company-management/models";
import { HISTORIES_PER_PAGE } from "modules/company-management/utils/constants";
import { CompanyManagement } from "modules/company-management/models/store/CompanyManagement";
import { CompanyManagementActionsTypes } from "modules/company-management/store/actions/actions";
import { CompanyManagementActions } from "modules/company-management/store/actions/actionTypes";

const initialState: CompanyManagement = {
  isFetching: false,
  isLoading: false,
  isLoadingSyncPlanhat: false,
  isLoadingSyncYounium: false,
  errorMessages: [],
  errorCode: null,
  contractsErrorMessages: [],
  successMessage: "",
  noDataMessage: "",
  noDataHistoriesMessage: "",
  companies: [],
  company: null,
  createdCompanyId: null,
  currencies: [],
  churnTypes: [],
  bots: [],
  mediapartners: [],
  totalItems: 0,
  totalPages: 1,
  currentPage: 1,
  clearbit: null,
  salesPeople: [],
  contracts: [],
  industries: [],
  industrySubClasses: [],
  isContractModalVisible: false,
  adminLogs: {
    items: [],
    meta: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
    },
  } as any,
  platforms: [],
  links: null,
  histories: [],
  hasMoreHistories: true,
  redirectOptions: [],
  models: {
    list: [],
    selected: [],
  },
};

const companyManagementReducer = (
  state: CompanyManagement = initialState,
  action: CompanyManagementActions,
): CompanyManagement => {
  switch (action.type) {
  case CompanyManagementActionsTypes.REMOVE_COMPANY:
  case CompanyManagementActionsTypes.DELETE_USER_FROM_COMPANY:
  case CompanyManagementActionsTypes.SEND_CONTRACT:
  case CompanyManagementActionsTypes.SEND_OLD_CONTRACT:
  case CompanyManagementActionsTypes.FETCH_CURRENCIES:
  case CompanyManagementActionsTypes.FETCH_CHURN_TYPES:
  case CompanyManagementActionsTypes.FETCH_CLEARBIT:
  case CompanyManagementActionsTypes.ENABLE_DOMAIN:
  case CompanyManagementActionsTypes.DISABLE_DOMAIN:
  case CompanyManagementActionsTypes.DUPLICATE_COMPANY:
  case CompanyManagementActionsTypes.FETCH_SALES_PEOPLE:
  case CompanyManagementActionsTypes.FETCH_COMPANY_CONTRACTS:
  case CompanyManagementActionsTypes.FETCH_INDUSTRIES:
  case CompanyManagementActionsTypes.FETCH_INDUSTRY_SUB_CLASSES:
  case CompanyManagementActionsTypes.TOGGLE_COMPANY_ACTIVATED:
  case CompanyManagementActionsTypes.CREATE_CONTRACT:
  case CompanyManagementActionsTypes.EDIT_CONTRACT:
  case CompanyManagementActionsTypes.BULK_SAVE_CONTRACTS:
  case CompanyManagementActionsTypes.REMOVE_PLATFORM:
  case CompanyManagementActionsTypes.CREATE_PLATFORM:
  case CompanyManagementActionsTypes.EDIT_PLATFORM:
  case CompanyManagementActionsTypes.FETCH_CONTRACT_HISTORIES: {
    return {
      ...state,
      isFetching: true,
      isLoading: true,
      errorMessages: [],
      errorCode: null,
      contractsErrorMessages: [],
      noDataMessage: "",
      successMessage: "",
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANIES_TO_SWITCH: {
    return {
      ...state,
      isLoading: true,
      errorMessages: [],
      redirectOptions: [
        {
          userID: 1,
          name: "Loading...",
          switchURL: "0",
          isVerified: false,
          roles: { internal: [], custom: [] },
        },
      ],
    };
  }

  case CompanyManagementActionsTypes.EDIT_SINGLE_COMPANY_LIMITS:
  case CompanyManagementActionsTypes.FETCH_SINGLE_COMPANY: {
    return {
      ...state,
      isLoading: true,
      isFetching: true,
      errorMessages: [],
      company: null,
    };
  }
  case CompanyManagementActionsTypes.FETCH_COMPANY_LOGS: {
    return {
      ...state,
      isLoading: true,
      isFetching: true,
      errorMessages: [],
      adminLogs: { items: [], meta: {} as any },
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANY_LOGS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      adminLogs: merge(action.payload, state.adminLogs),
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANIES:
  case CompanyManagementActionsTypes.FETCH_BOTS:
  case CompanyManagementActionsTypes.FETCH_PLATFORMS: {
    return {
      ...state,
      isLoading: true,
      isFetching: true,
      noDataMessage: "",
      errorMessages: [""],
      company: null,
      links: null,
    };
  }

  case CompanyManagementActionsTypes.ADD_COMPANY: {
    return {
      ...state,
      isLoading: true,
      isFetching: true,
      errorMessages: [],
      company: action.payload?.data.company,
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY: {
    return {
      ...state,
      isLoading: true,
      isFetching: true,
      errorMessages: [],
      company: {
        ...state.company,
        ...action.payload?.data.company,
      },
    };
  }

  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_PLANHAT: {
    return {
      ...state,
      isLoadingSyncPlanhat: true,
      successMessage: "",
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_YOUNIUM: {
    return {
      ...state,
      isLoadingSyncYounium: true,
      successMessage: "",
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY_MODULES:
  case CompanyManagementActionsTypes.EDIT_COMPANY_LIMITS:
  case CompanyManagementActionsTypes.EDIT_COMPANY_SETTING:
  case CompanyManagementActionsTypes.GET_CURRENT_LIMITS:
  case CompanyManagementActionsTypes.POPULATE_HISTORICAL_LEADS: {
    return {
      ...state,
      isLoading: true,
      successMessage: "",
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.TOGGLE_CONTRACT_MODAL_VISIBLE: {
    return {
      ...state,
      isLoading: true,
      isContractModalVisible: !state.isContractModalVisible,
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANIES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      companies: action.payload.items,
      noDataMessage: isEmpty(action.payload.items || action.payload.items)
        ? "There are no companies yet!"
        : "",
      totalItems: action.payload?.meta.totalItems,
      totalPages: action.payload?.meta.totalPages,
      currentPage: Number(action.payload?.meta.currentPage),
      links: action.payload.links,
    };
  }

  case CompanyManagementActionsTypes.FETCH_SINGLE_COMPANY_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      company: action.payload.company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_SINGLE_COMPANY_LIMITS_SUCCESS: {
    const newCompany = {
      ...state.company,
      limits: action.payload.data,
    };

    return {
      ...state,
      isLoading: false,
      isFetching: false,
      company: newCompany as Company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.ADD_COMPANY_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      createdCompanyId: action.payload.insertId,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      company: isEmpty(state.company) ? null : action.payload.company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY_MODULES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      companies: state.companies.map((item) =>
        item.id === Number(action.payload.data.companyId)
          ? {
            ...item,
            module: {
              ...item.module,
              [action.payload.data.key]: action.payload.data.value,
            },
          }
          : item,
      ),
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY_LIMITS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      companies: state.companies.map((item) =>
        item.id === Number(action.payload.data.companyId)
          ? {
            ...item,
            limits: action.payload.data.limits,
          }
          : item,
      ),
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.GET_CURRENT_LIMITS_SUCCESS: {
    const { company_id, ...limits } = action.payload;
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      companies: state.companies.map((item) =>
        item.id === Number(company_id)
          ? {
            ...item,
            current_limits: limits,
          }
          : item,
      ),
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.EDIT_COMPANY_SETTING_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      company:
          state.company && state.company.setting
            ? {
              ...state.company,
              setting: { ...state.company.setting, ...action.payload },
            }
            : state.company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.POPULATE_HISTORICAL_LEADS_SUCCESS:
  case CompanyManagementActionsTypes.REMOVE_COMPANY_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      successMessage:
          typeof action.payload === "string" ? action.payload : "",
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SHOW_COMPANIES_PAGE: {
    return {
      ...state,
      companies: [],
    };
  }

  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_PLANHAT_SUCCESS: {
    return {
      ...state,
      isLoadingSyncPlanhat: false,
      successMessage: action.payload.message,
      company: action.payload.company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_YOUNIUM_SUCCESS: {
    return {
      ...state,
      isLoadingSyncYounium: false,
      successMessage: action.payload.message,
      company: action.payload.company,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SEND_CONTRACT_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      successMessage: action.payload?.message,
      isContractModalVisible: false,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SEND_OLD_CONTRACT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoading: false,
      successMessage: action.payload.message,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_CURRENCIES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      currencies: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_CHURN_TYPES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      churnTypes: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_CLEARBIT_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      clearbit: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.ENABLE_DOMAIN_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      successMessage: action.payload?.message,
      clearbit: action.payload.clearbit,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.SET_LIMIT_SUCCESS: {
    return {
      ...state,
      clearbit: state.clearbit
        ? { ...state.clearbit, limit: action.payload }
        : null,
    };
  }

  case CompanyManagementActionsTypes.DISABLE_DOMAIN_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      clearbit: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_BOTS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      bots: action.payload.bots,
      mediapartners: action.payload.companies,
      errorMessages: [
        action.payload.isMedia ? "" : "Media Partner module must be enabled",
      ],
      noDataMessage: isEmpty(action.payload.bots || action.payload.companies)
        ? "There are no bots and/or media partners yet!"
        : "",
    };
  }

  case CompanyManagementActionsTypes.FETCH_SALES_PEOPLE_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      salesPeople: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANY_CONTRACTS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      contracts: action.payload,
      noDataMessage: isEmpty(action.payload)
        ? "There are no company contracts yet!"
        : "",
      contractsErrorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_INDUSTRIES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      industries: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_INDUSTRY_SUB_CLASSES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      industrySubClasses: action.payload,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANIES_TO_SWITCH_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      redirectOptions: action.payload.data.accounts,
      errorMessages: [],
    };
  }

  case CompanyManagementActionsTypes.DELETE_USER_FROM_COMPANY_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      successMessage: "User successfully deleted",
      redirectOptions: state.redirectOptions.filter(
        (option) => option.userID !== Number(action.payload.data.userID),
      ),
    };
  }

  case CompanyManagementActionsTypes.DUPLICATE_COMPANY_SUCCESS: {
    setTimeout(() => {
      if (action.payload.id) {
        window.location.href = `/company/view/${action.payload.id}/details`;
      }
    }, 3000);

    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      successMessage: "Company Duplicated! Redirecting in 3 secs...",
    };
  }

  case CompanyManagementActionsTypes.RESEND_ACTIVATION_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
    };
  }

  case CompanyManagementActionsTypes.TOGGLE_COMPANY_ACTIVATED_SUCCESS: {
    const churnPayload: CompanyChurnData = action.payload.churn
      ? action.payload.churn
      : {
        churn_at: "",
        churn_id: 0,
        churn_description: null,
        deactivate_at: "",
      };

    return {
      ...state,
      isLoading: false,
      isFetching: false,
      createdCompanyId: action.payload!.id,
      company: {
        ...state.company,
        ...churnPayload,
        contract_active: state.company!.contract_active
          ? {
            ...state.company!.contract_active,
            ...churnPayload,
          }
          : null,
        is_active: action.payload!.is_active,
      } as Company,
      contracts: isEmpty(state.contracts)
        ? state.contracts
        : state.contracts.map(
          (contract) =>
                ({
                  ...contract,
                  ...churnPayload,
                }) as unknown as Contract,
        ),
    };
  }

  case CompanyManagementActionsTypes.CREATE_CONTRACT_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      contracts: [...state.contracts, action.payload],
    };
  }

  case CompanyManagementActionsTypes.EDIT_CONTRACT_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      contracts: state.contracts.map((contract) =>
        contract.id === action.payload.id
          ? { ...contract, ...action.payload }
          : contract,
      ),
      noDataMessage: isEmpty(action.payload)
        ? "There are no contracts yet!"
        : "",
    };
  }

  case CompanyManagementActionsTypes.BULK_SAVE_CONTRACTS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      contracts: action.payload,
    };
  }

  case CompanyManagementActionsTypes.FETCH_PLATFORMS_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      platforms: action.payload,
      noDataMessage: isEmpty(action.payload)
        ? "There are no platforms yet!"
        : "",
    };
  }

  case CompanyManagementActionsTypes.CREATE_PLATFORM_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      platforms: [...state.platforms, action.payload],
    };
  }

  case CompanyManagementActionsTypes.REMOVE_PLATFORM_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      platforms: state.platforms.filter(
        (platform) => platform.id !== Number(action.payload.platformID),
      ),
    };
  }

  case CompanyManagementActionsTypes.EDIT_PLATFORM_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      platforms: state.platforms.map((platform) =>
        platform.id === action.payload.id
          ? { ...platform, ...action.payload }
          : platform,
      ),
    };
  }

  case CompanyManagementActionsTypes.FETCH_CONTRACT_HISTORIES_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      errorMessages: [],
      histories: [...state.histories, ...action.payload],
      noDataHistoriesMessage: isEmpty(action.payload)
        ? "There are no histories yet!"
        : "",
      hasMoreHistories: action.payload.length >= HISTORIES_PER_PAGE,
    };
  }

  case CompanyManagementActionsTypes.CLEAN_CONTRACT_HISTORIES: {
    return {
      ...state,
      isLoading: false,
      histories: [],
    };
  }

  case CompanyManagementActionsTypes.FETCH_COMPANIES_ERROR:
  case CompanyManagementActionsTypes.FETCH_COMPANIES_TO_SWITCH_ERROR:
  case CompanyManagementActionsTypes.FETCH_SINGLE_COMPANY_ERROR:
  case CompanyManagementActionsTypes.EDIT_SINGLE_COMPANY_LIMITS_ERROR:
  case CompanyManagementActionsTypes.ADD_COMPANY_ERROR:
  case CompanyManagementActionsTypes.EDIT_COMPANY_ERROR:
  case CompanyManagementActionsTypes.EDIT_COMPANY_MODULES_ERROR:
  case CompanyManagementActionsTypes.EDIT_COMPANY_LIMITS_ERROR:
  case CompanyManagementActionsTypes.EDIT_COMPANY_SETTING_ERROR:
  case CompanyManagementActionsTypes.REMOVE_COMPANY_ERROR:
  case CompanyManagementActionsTypes.DELETE_USER_FROM_COMPANY_ERROR:
  case CompanyManagementActionsTypes.DUPLICATE_COMPANY_ERROR:
  case CompanyManagementActionsTypes.SEND_CONTRACT_ERROR:
  case CompanyManagementActionsTypes.SEND_OLD_CONTRACT_ERROR:
  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_PLANHAT_ERROR:
  case CompanyManagementActionsTypes.SYNC_COMPANY_WITH_YOUNIUM_ERROR:
  case CompanyManagementActionsTypes.FETCH_CURRENCIES_ERROR:
  case CompanyManagementActionsTypes.FETCH_CHURN_TYPES_ERROR:
  case CompanyManagementActionsTypes.ENABLE_DOMAIN_ERROR:
  case CompanyManagementActionsTypes.SET_LIMIT_ERROR:
  case CompanyManagementActionsTypes.DISABLE_DOMAIN_ERROR:
  case CompanyManagementActionsTypes.FETCH_BOTS_ERROR:
  case CompanyManagementActionsTypes.FETCH_SALES_PEOPLE_ERROR:
  case CompanyManagementActionsTypes.FETCH_COMPANY_CONTRACTS_ERROR:
  case CompanyManagementActionsTypes.FETCH_INDUSTRIES_ERROR:
  case CompanyManagementActionsTypes.FETCH_INDUSTRY_SUB_CLASSES_ERROR:
  case CompanyManagementActionsTypes.TOGGLE_COMPANY_ACTIVATED_ERROR:
  case CompanyManagementActionsTypes.CREATE_CONTRACT_ERROR:
  case CompanyManagementActionsTypes.EDIT_CONTRACT_ERROR:
  case CompanyManagementActionsTypes.BULK_SAVE_CONTRACTS_ERROR:
  case CompanyManagementActionsTypes.FETCH_PLATFORMS_ERROR:
  case CompanyManagementActionsTypes.CREATE_PLATFORM_ERROR:
  case CompanyManagementActionsTypes.REMOVE_PLATFORM_ERROR:
  case CompanyManagementActionsTypes.EDIT_PLATFORM_ERROR:
  case CompanyManagementActionsTypes.FETCH_CONTRACT_HISTORIES_ERROR:
  case CompanyManagementActionsTypes.GET_AI_MODELS_ERROR:
  case CompanyManagementActionsTypes.GET_SELECTED_AI_MODELS_ERROR:
  case CompanyManagementActionsTypes.POST_SELECTED_AI_MODELS_ERROR:
  case CompanyManagementActionsTypes.DELETE_SELECTED_AI_MODELS_ERROR:
  case CompanyManagementActionsTypes.POPULATE_HISTORICAL_LEADS_ERROR: {
    const contractsErrorCases = [
      CompanyManagementActionsTypes.BULK_SAVE_CONTRACTS_ERROR,
      CompanyManagementActionsTypes.FETCH_COMPANY_CONTRACTS_ERROR,
    ];
    let errors;
    if (isArray(action.payload)) {
      errors = action.payload;
    } else {
      errors =
          typeof action.payload.message === "string"
            ? [action.payload.message]
            : [...Object.values(action.payload.errors).map((msg) => msg[0])];
    }

    return {
      ...state,
      isLoading: false,
      isFetching: false,
      isLoadingSyncPlanhat: false,
      errorMessages: [...state.errorMessages, ...errors],
      contractsErrorMessages: contractsErrorCases.includes(action.type)
        ? [...state.contractsErrorMessages, ...errors]
        : [...state.contractsErrorMessages],
      redirectOptions: [],
      successMessage: "",
    };
  }

  case CompanyManagementActionsTypes.FETCH_CLEARBIT_ERROR: {
    let errors;
    if (isArray(action.payload.error)) {
      errors = action.payload.error;
    } else {
      errors =
          typeof action.payload.error.message === "string"
            ? [action.payload.error.message]
            : [
              ...Object.values(action.payload.error.errors).map(
                (msg) => msg[0],
              ),
            ];
    }

    return {
      ...state,
      isLoading: false,
      isFetching: false,
      isLoadingSyncPlanhat: false,
      errorCode: action.payload.errorCode,
      errorMessages: [...state.errorMessages, ...errors],
      contractsErrorMessages: state.contractsErrorMessages,
      redirectOptions: [],
      successMessage: "",
    };
  }

  case CompanyManagementActionsTypes.GET_AI_MODELS_SUCCESS:
    return { ...state, models: { ...state.models, list: action.payload } };
  case CompanyManagementActionsTypes.GET_SELECTED_AI_MODELS_SUCCESS:
    return {
      ...state,
      models: { ...state.models, selected: action.payload },
    };
  case CompanyManagementActionsTypes.POST_SELECTED_AI_MODELS_SUCCESS:
    return {
      ...state,
      models: {
        ...state.models,
        selected: [...state.models.selected, action.payload],
      },
    };
  case CompanyManagementActionsTypes.DELETE_SELECTED_AI_MODELS_SUCCESS:
    return {
      ...state,
      models: {
        ...state.models,
        selected: state.models.selected.filter(
          (model) => model !== action.payload,
        ),
      },
    };
  default:
    return state;
  }
};

export default companyManagementReducer;
